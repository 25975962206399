import PageContainer from '../../components/Charge/PageContainer'
import { ReactComponent as PageIllustrationVerify } from '../../images/analyze-small.svg'
import { ReactComponent as ActivationSuccessIllustration } from '../../images/activation-success.svg'
// import { ReactComponent as PageMockup } from '../../images/page-mockup.svg'
import { ReactComponent as ProgressX } from '../../images/progress-new.svg'
import { ReactComponent as Logo } from '../../images/logo.svg'
import { Alert, Button, Typography } from 'antd'
import { useRef, useState, useEffect } from 'react'
import OnboardingSteps from '../../components/OnboardingSteps'
import ActivationFailed from '../ActivationFailed'
import { isDebug } from '../../lib/debug'
import './Analyze.scss'

const { Title, Paragraph } = Typography

function Header() {
  return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Logo />
    <OnboardingSteps current={3} />
  </div>
}

function ActivationFailedBlock({ action }) {
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Show Activation Failed Screen"
    action={
      <Button
        size="large"
        type="default"
        onClick={action}
      >
        Show
      </Button>
    }
  />
}

function ActivationSuccessBlock({ action }) {
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Show Activation Success Screen"
    action={
      <Button
        size="large"
        type="default"
        onClick={action}
      >
        Show
      </Button>
    }
  />
}

function ActivationProgressBlock({ action }) {
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Show Activation Progress Screen"
    action={
      <Button
        size="large"
        type="default"
        onClick={action}
      >
        Show
      </Button>
    }
  />
}

function SkipAnalysingProcessBlock({ action }) {
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Skip Analysing Process"
    action={
      <Button
        size="large"
        type="default"
        onClick={action}
      >
        Skip
      </Button>
    }
  />
}

function BackBlock({ action }) {
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Back to Analyze page"
    action={
      <Button
        size="large"
        type="default"
        onClick={action}
      >
        Go
      </Button>
    }
  />
}

function ProgressContent({ primaryAction = () => null, secondaryAction = () => null }) {
  const wrapper = useRef()
  const progressRef = useRef()
  const timeRef = useRef()
  const intervalRef = useRef()
  const [percent, setPercent] = useState(0)
  const [ { width, height }, setSize ] = useState({ width: 0, height: 0 })
  useEffect(() => {
    console.log('wrapper', wrapper.current, wrapper.current.parentNode)
    const width= wrapper.current.parentNode.offsetWidth
    const height = wrapper.current.parentNode.offsetHeight - 20
    if (width/height > 1485/1130) {
      setSize({
        width: Math.round(1485 * height / 1130),
        height,
      })
    } else {
      setSize({
        width,
        height: Math.round(1130 * width / 1485),
      })
    }
    setTimeout(() => {
      progressRef.current.style.width = '100%'
      timeRef.current = new Date()
      intervalRef.current = setInterval(() => {
        const newPercent = Math.ceil((new Date() - timeRef.current) * 100 / (50 * 1000))
        if (newPercent >= 100) {
          clearInterval(intervalRef.current)
          setPercent(100)
          primaryAction({  })
          return
        }
        if (newPercent !== percent) {
          setPercent(newPercent)
        }
      }, 100)
      const progressElement = window.document.getElementById('analyze-progress-ellipse')
      if (progressElement) {
        progressElement.classList.add('analyze-progress-ellipse-animate')
      }
    }, (1000))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div
    style={{
      width,
      height,
      position: 'relative',
      borderRadius: '4%',
      overflow: 'hidden',
      margin: '0 auto',
    }}
    ref={wrapper}
  >
    {/* <PageMockup /> */}
    <img src={process.env.REACT_APP_PUBLIC_URL + '/images/page-mockup.svg'} alt="" style={{ width: '100%' }} />
    <div
      ref={progressRef}
      style={{
        position: 'absolute',
        left: '0',
        top: '0',
        bottom: '0',
        width: '0%',
        transition: 'width',
        transitionDuration: '50s',
        transitionTimingFunction: 'linear',
        backgroundColor: 'rgba(5, 66, 156, 0.73)',
      }}
    >

    </div>
    <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
      <ProgressX />
      <div style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '1.5vw',
        marginTop: '-2px',
      }}>{percent}%</div>
    </div>
  </div>
}

function Content({ primaryAction = () => null, secondaryAction = () => null }) {
  useEffect(() => {
    window.gtag('event', 'View', {
      event_category: 'Page',
      event_label: '06_analysis_ga',
    })
  }, [])

  return <>
    <Title>
      We will analyze your site,<br /> this should take only <span className="blue-text">50 seconds</span>
    </Title>
    <Paragraph>
      Our AI will automatically re-scan all your pages every 24 hours to make the necessary updates.
    </Paragraph>
    <div className="page-button" style={{ display: 'flex', gap: '20px' }}>
      <Button type="primary" size="large" onClick={primaryAction}>
        Start Analyzing
      </Button>
    </div>
  </>
}

function SuccessContent({ primaryAction = () => null, secondaryAction = () => null }) {
  return <>
    <Title>
      Your site has been analyzed
    </Title>
    <Paragraph>
      Our AI will automatically re-scan all your pages every 24 hours to make the necessary updates.
    </Paragraph>
    <div className="page-button" style={{ display: 'flex', gap: '20px' }}>
      <Button type="primary" size="large" onClick={primaryAction}>
        Continue &gt;
      </Button>
    </div>
  </>
}

function Analyze({ primaryAction }) {
  const [progress, setProgress] = useState(false)
  const [activationFailed, setActivationFailed] = useState(false)
  const [activationSuccess, setActivationSuccess] = useState(false)

  if (activationSuccess) {
    return (
      <PageContainer
        header={<Header />}
        leftBlock={<>
          <SuccessContent primaryAction={primaryAction} />
          {isDebug() && <BackBlock action={() => setActivationSuccess(false)} />}
        </>}
        rightBlock={(<ActivationSuccessIllustration  />)}
      />
    )
  }

  if (activationFailed) {
    return (
      <ActivationFailed setActivationFailed={setActivationFailed} />
    )
  }

  if (progress) {
    return (
      <PageContainer
        header={<Header />}
        leftBlock={<ProgressContent primaryAction={() => setActivationSuccess(true)} />}
      />
    )
  }

  return (
    <PageContainer
      header={<Header />}
      leftBlock={<>
        <Content primaryAction={() => setProgress(true)} />
        {isDebug() && <ActivationFailedBlock action={() => setActivationFailed(true)} />}
        {isDebug() && <ActivationSuccessBlock action={() => setActivationSuccess(true)} />}
        {isDebug() && <ActivationProgressBlock action={() => setProgress(true)} />}
        {isDebug() && <SkipAnalysingProcessBlock action={() => primaryAction()} />}
      </>}
      rightBlock={(<PageIllustrationVerify  />)}
    />
  )
}

export default Analyze
