import PageContainer from '../../components/Charge/PageContainer'
// import { ReactComponent as PageIllustration } from '../../images/onboarding-welcome.svg'
import { ReactComponent as Logo } from '../../images/logo.svg'
import { Button, Typography } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { useEffect } from 'react'

const { Title, Paragraph } = Typography

function Header() {
  return <div><Logo /></div>
}

function Content({ primaryAction = () => null }) {
  return <>
    <Title style={{ marginTop: '80px' }}>
      The #1 automated <span className="blue-text">Web Accessibility</span> solution app
    </Title>
    <Paragraph>
      We'll support your compliance to ADA, WCAG 2.1, s508, and many other worldwide accessibility legislations.
    </Paragraph>
    <div />
    <div />
    <div className="page-button">
      <Button type="primary" size="large" onClick={primaryAction}>
        Let's go <RightOutlined />
      </Button>
    </div>
  </>
}

function Welcome ({ primaryAction }) {
  useEffect(() => {
    window.gtag('event', 'View', {
      event_category: 'Page',
      event_label: '01_welcome_ga',
    })
  }, [])
  return (
    <PageContainer
      header={<Header />}
      leftBlock={<Content primaryAction={primaryAction} />}
      rightBlock={(<img src={`${process.env.REACT_APP_PUBLIC_URL}/images/onboarding-welcome.svg`} alt="" />)}
    />
  )
}

export default Welcome
