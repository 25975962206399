import PageContainer from '../../components/Charge/PageContainer'
import { ReactComponent as PageIllustration } from '../../images/billing-cancelled.svg'
import { ReactComponent as PageIcon } from '../../images/completed-icon.svg'
import { ReactComponent as Logo } from '../../images/logo.svg'
import { Button, Space, Typography } from 'antd'
import useCustomHistory from '../../hooks/useCustomHistory'
import { useEffect } from 'react'

const { Title, Paragraph } = Typography

function Header() {
  return <div><Logo /></div>
}

function Content({ primaryAction = () => null }) {
  const history = useCustomHistory()
  useEffect(() => {
    window.gtag('event', 'View', {
      event_category: 'Page',
      event_label: '10_cancelsurveyconfirm_ga',
    })
  }, [])
  return <div className="rows rows-align-center" style={{ maxWidth: 600 }}>
    <PageIcon />
    <Title>
      <span className="blue-text">XCompliant</span> subscription cancelled
    </Title>
    <Paragraph style={{ textAlign: 'center' }}>
      We're sorry that you have decided to stop using our service.
      It would really help us out a ton if you could tell us why you decided to stop using XCompliant.
    </Paragraph>
    <Space>
      <Button type="default" size="large" onClick={() => history.push('/')}>
        Go back to the dashboard
      </Button>
      <Button type="primary" size="large">
        Contact Support
      </Button>
    </Space>
  </div>
}

function Cancelled ({ primaryAction }) {
  return (
    <PageContainer
      header={<Header />}
      leftBlock={<Content primaryAction={primaryAction} />}
      rightBlock={(<PageIllustration />)}
    />
  )
}

export default Cancelled
