import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import './lib/rollbar'

if (process.env.REACT_APP_GA_TOKEN) {
  const gaScript = document.createElement('script')
  gaScript.type = 'text/javascript'
  gaScript.async = true
  gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TOKEN}`
  document.body.appendChild(gaScript)
}
window.dataLayer = window.dataLayer || []
window.gtag = function () {
  window.dataLayer.push(arguments)
}
window.gtag('js', new Date())
window.gtag('config', process.env.REACT_APP_GA_TOKEN)

ReactDOM.render(
  <App />,
  document.getElementById('root'),
)

if (process.env.REACT_APP_TAWK_SRC) {
  const tawkScript = document.createElement('script')
  tawkScript.type = 'text/javascript'
  tawkScript.async = true
  tawkScript.innerHTML = `
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='${process.env.REACT_APP_TAWK_SRC}';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  `
  document.body.appendChild(tawkScript)
}

if (process.env.REACT_APP_GROOVE_TOKEN) {
  const grooveScript = document.createElement('script')
  grooveScript.type = 'text/javascript'
  grooveScript.async = true
  grooveScript.innerHTML = `
    !function(e,t){if(!e.groove){var i=function(e,t){return Array.prototype.slice.call(e,t)},a={widget:null,loadedWidgets:{},classes:{Shim:null,Embeddable:function(){this._beforeLoadCallQueue=[],this.shim=null,this.finalized=!1;var e=function(e){var t=i(arguments,1);if(this.finalized){if(!this[e])throw new TypeError(e+"() is not a valid widget method");this[e].apply(this,t)}else this._beforeLoadCallQueue.push([e,t])};this.initializeShim=function(){a.classes.Shim&&(this.shim=new a.classes.Shim(this))},this.exec=e,this.init=function(){e.apply(this,["init"].concat(i(arguments,0))),this.initializeShim()},this.onShimScriptLoad=this.initializeShim.bind(this),this.onload=void 0}},scriptLoader:{callbacks:{},states:{},load:function(e,i){if("pending"!==this.states[e]){this.states[e]="pending";var a=t.createElement("script");a.id=e,a.type="text/javascript",a.async=!0,a.src=i;var s=this;a.addEventListener("load",(function(){s.states[e]="completed",(s.callbacks[e]||[]).forEach((function(e){e()}))}),!1);var n=t.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n)}},addListener:function(e,t){"completed"!==this.states[e]?(this.callbacks[e]||(this.callbacks[e]=[]),this.callbacks[e].push(t)):t()}},createEmbeddable:function(){var t=new a.classes.Embeddable;return e.Proxy?new Proxy(t,{get:function(e,t){return e instanceof a.classes.Embeddable?Object.prototype.hasOwnProperty.call(e,t)||"onload"===t?e[t]:function(){e.exec.apply(e,[t].concat(i(arguments,0)))}:e[t]}}):t},createWidget:function(){var e=a.createEmbeddable();return a.scriptLoader.load("groove-script","https://0562d9ff-a412-4b04-b344-8a129520d005.widget.cluster.groovehq.com/api/loader"),a.scriptLoader.addListener("groove-iframe-shim-loader",e.onShimScriptLoad),e}};e.groove=a}}(window,document);
    window.groove.widget = window.groove.createWidget();
    window.groove.widget.init('${process.env.REACT_APP_GROOVE_TOKEN}', {});
  `
  document.body.appendChild(grooveScript)
}
