import { Button, Select, Form, Input, Radio, Card } from 'antd'
import React, { useEffect, useRef } from 'react'
import SpinnerWithMessage from '../../components/SpinnerWithMessage/SpinnerWithMessage'
import MobilePreview from './MobilePreview/MobilePreview'
import './Settings.scss'

const Settings = ({ shop, updateShop, saving, onboarding }) => {
  const [form] = Form.useForm()
  const leadColor = Form.useWatch('leadColor', form)

  useEffect(() => {
    window.gtag('event', 'View', {
      event_category: 'Page',
      event_label: onboarding ? '05_widgetcustom_ga' : '08_widgetcustominapp_ga',
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = (values) => {
    updateShop({
      widgetSettings: values,
      ...(onboarding && { onboardingPassedStage: 'settings' }),
    })
  }

  const initValues = {
    statementLink: '',
    hideMobile: 'false',
    hideTrigger: 'false',
    language: 'en',
    position: 'left',
    leadColor: '#0000ff',
    triggerColor: '#0000ff',
    triggerRadius: '50%',
    triggerPositionX: 'right',
    triggerPositionY: 'bottom',
    triggerIcon: 'people',
    triggerSize: 'medium',
    triggerOffsetX: '20',
    triggerOffsetY: '20',
    ...(shop.widgetSettings || {}),
    mobile: {
      triggerSize: 'small',
      triggerPositionX: 'right',
      triggerPositionY: 'center',
      triggerOffsetX: '10',
      triggerOffsetY: '0',
      triggerRadius: '50%',
      ...(shop.widgetSettings && shop.widgetSettings.mobile || {}),
    },
  }

  const scriptText = useRef('')

  const languageArray = [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' },
    { label: 'Français', value: 'fr' },
    { label: 'Deutsche', value: 'de' },
    { label: 'Italiano', value: 'it' },
    { label: 'Português', value: 'pt' },
    { label: 'Nederlands', value: 'nl' },
    { label: '日本語', value: 'jp' },
    { label: '台灣', value: 'tw' },
    { label: '中文', value: 'ct' },
    { label: 'עברית', value: 'he' },
    { label: 'русский', value: 'ru' },
    { label: 'الإمارات العربية المتحدة', value: 'ar' },
    // { label: 'عربى', value: 'ar' },
  ]

  const hPosArray = [
    { label: 'Left', value: 'left' },
    { label: 'Right', value: 'right' },
  ]

  const vPosArray = [
    { label: 'Top', value: 'top' },
    { label: 'Center', value: 'center' },
    { label: 'Bottom', value: 'bottom' },
  ]

  const btnShapeArray = [
    { label: 'Round', value: '50%' },
    { label: 'Square', value: '0' },
    { label: 'Squircle Big', value: '10px' },
    { label: 'Squircle Small', value: '5px' },
  ]

  const sizeArray = [
    { label: 'Small', value: 'small' },
    { label: 'Medium', value: 'medium' },
    { label: 'Big', value: 'big' },
  ]

  const hideArray = [
    { label: 'Show', value: 'false' },
    { label: 'Hide', value: 'true' },
  ]

  const iconValues = [
    'display2',
    'settings',
    'display3',
    'wheels',
    'people',
    'display',
    'settings2',
    'help',
    'people2',
    'wheels2',
  ]

  const Preview = () => (
    <div className="script-form-previews">
      <MobilePreview color={leadColor} />
      <div className="scrip-preview">
        {
          scriptText.current.trim() !== '' && (
            <Input
              value={scriptText.current.trim()}
              multiline={2}
            />
          )
        }
      </div>
    </div>
  )

  const FormPartInterfaceCustomizations = () => (
    <div className="script-form-block">
      <div className="block-title">
        Interface Customizations
      </div>
      <div className="block-body">
        <Form.Item
          label="Interface Lead Color"
          name="leadColor"
        >
          <Input type="color" />
        </Form.Item>
        <Form.Item
          label="Accessibility Statement Link"
          name="statementLink"
          // rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input
            placeholder="Leave blank for default"
          />
        </Form.Item>
        <Form.Item
          label="Interface Position"
          name="position"
        >
          <Select>
            {hPosArray.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Interface Language"
          name="language"
        >
          <Select>
            {languageArray.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    </div>
  )

  const FormPartTriggerCustomizations = () => (
    <div className="script-form-block">
      <div className="block-title">
        Trigger Customizations
      </div>
      <div className="block-body">
        <Form.Item
          label="Trigger Button Color"
          name="triggerColor"
        >
          <Input type="color" />
        </Form.Item>
        <Form.Item
          label="Trigger Vertical Position"
          name="triggerPositionY"
        >
          <Select>
            {vPosArray.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Trigger Button Shape"
          name="triggerRadius"
        >
          <Select>
            {btnShapeArray.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Trigger Horizontal Offset"
          name="triggerOffsetX"
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Trigger Horizontal Position"
          name="triggerPositionX"
        >
          <Select>
            {hPosArray.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Trigger Button Size"
          name="triggerSize"
        >
          <Select>
            {sizeArray.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Hide Trigger Button"
          name="hideTrigger"
        >
          <Select>
            {hideArray.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Trigger Vertical Offset"
          name="triggerOffsetY"
        >
          <Input type="number" />
        </Form.Item>
        <div className="icon-block">
          <Form.Item
            label="Trigger Button Icon"
            name="triggerIcon"
            style={{ marginBottom: '-16px' }}
          >
            {/* <ul className="icon-list"> */}
            <Radio.Group size="large">
              {iconValues && iconValues.map((svg, index) => (
                <Radio
                  key={index}
                  value={iconValues[index]}
                >
                  <img alt="" src={`${process.env.REACT_APP_PUBLIC_URL}/images/trigerIcons/${svg}.svg`} />
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
    </div>
  )

  const FormPartMobileCustomizations = () => (
    <div className="script-form-block">
      <div className="block-title">
        Mobile Customizations
      </div>
      <div className="block-body">
        <Form.Item
          label="Show on mobile?"
          name="hideMobile"
        >
          <Select>
            {hideArray.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Mobile Trigger Vertical Position"
          name={['mobile', 'triggerPositionY']}
        >
          <Select>
            {vPosArray.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Trigger Mobile Shape"
          name={['mobile', 'triggerRadius']}
        >
          <Select>
            {btnShapeArray.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Mobile Trigger Vertical Offset"
          name={['mobile', 'triggerOffsetY']}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Mobile Trigger Horizontal Position"
          name={['mobile', 'triggerPositionX']}
        >
          <Select>
            {hPosArray.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Mobile Trigger Size"
          name={['mobile', 'triggerSize']}
        >
          <Select>
            {sizeArray.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Mobile Trigger Horizontal Offset"
          name={['mobile', 'triggerOffsetX']}
        >
          <Input type="number" />
        </Form.Item>
      </div>
    </div>
  )

  const SettingsForm = () => (
    <div className="script-block-list">
      <FormPartTriggerCustomizations />
      <FormPartInterfaceCustomizations />
      <FormPartMobileCustomizations />
    </div>
  )

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={initValues}
      scrollToFirstError
    >
      <div className={`script-form ${onboarding && 'onboarding' || ''}`}>
        <SpinnerWithMessage isLoading={saving} message="Saved" />
        <div className="script-form-body">
          <Preview />
          <Card
            className={onboarding ? 'onboarding' : 'not-onboarding'}
            title={onboarding ? 'Settings' : null}
            style={{ width: '58%' }}
          >
            <SettingsForm />
            <div className="script-form-footer">
              <div className="footer-content">
                <Button
                  type="primary"
                  // size="large"
                  htmlType="submit"
                >
                  {onboarding ? 'Continue' : 'Save Changes'}
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Form>
  )
}

export default Settings
